.course-edit {
    /* position: absolute; */
    /* position: fixed;
    overflow: auto;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 2% 0px;
    font-size: 20px;
    overflow: auto;
    backdrop-filter: blur(3px); */
    width: 100%;
    height: 100vh;
    overflow: auto;
    /* background-color: rgba(0, 0, 0, 1); */
    color: white;
    z-index: 10;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    align-items: flex-start;
    box-sizing: border-box;
    padding: 2% 0px;
    font-size: 20px;
    /* overflow: auto; */
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
  .course-edit::-webkit-scrollbar {
    width: 7px;
  }
  
  .course-edit::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 15px 0;
  }
  
  .course-edit::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: #5DB0C7;
  }

  .course-edit__wrapper {
    /* max-width: 920px; */
    /* max-width: 720px; */
    position: relative;
    max-width: 1024px;
    box-sizing: border-box;
    padding: 0 25px;
    width: 100%;
  }

  .course-edit__wrapper-back {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 25px 0;
  }

  .course-edit__wrapper-back button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #26272c;
    color: white;
    border-radius: 10px;
    border: none;
    box-sizing: border-box;
    padding: 10px;
  }

  .course-edit__wrapper-back button p {
    margin: 0;
  }

  .course-edit__wrapper-back h3 {
    margin: 0;
  }

  .course-edit__wrapper-back-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px 0;
  }

  .course__info-back-btn-svg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(93, 176, 199);
    border: 2px solid rgb(93, 176, 199);
    color: black;
  }

  /* .course-edit__wrapper-back {
    padding: 0;
    width: 35px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid rgb(255, 255 ,255);
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .course-edit__headline {
    margin-top: 0;
    margin: 0 auto;
  }
  
  .course-edit__form {
    /* max-width: 360px; */
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: stretch;
    gap: 50px;
  }
  
  .course-edit__form-input {
    height: 30px;
    width: 100%;
    border: none;
    border-bottom: 2px solid white;
    box-sizing: border-box;
    background-color: transparent;
    color: white;
    outline: none;
    transition: all 0.25s ease-in-out;
  }
  
  .course-edit__form-input:focus {
    border-bottom: 2px solid #5DB0C7;
  }
  
  .course-edit__form-textarea {
    resize: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      border-radius: 12px;
      font-size: 16px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    transition: all 0.25s ease-in-out;
  }
  
  .course-edit__form-textarea:focus {
    outline: none;
    border: 2px solid #5DB0C7;
  }

  .course-edit__ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    padding: 0;
    list-style: none;
    gap: 30px;
    margin: 50px 0;
    min-height: 360px;
  }

  .course-edit__ul li {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    border-radius: 9px;
    padding: 15px 30px;
    /* min-height: 310px; */
    /* background-color: #100f13; */
    background-color: #26272c;
    transition: all 0.25s ease-in-out;
  }
  .course-edit__ul-li-div {
    align-self: flex-end;
    display: flex;
    gap: 5px;
  }

  .course-edit__ul-li-delete {
    align-self: flex-end;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border: none;
    background-color: #100f13;
    color: rgb(93, 176, 199);
    box-sizing: border-box;
    padding: 0;
    width: 30px;
  }

  .course-edit__ul li img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    /* max-width: 120px; */
    border-radius: 9px;
  }

  .course-edit__ul-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 35px;
    /* background-color: #d4d4d6; */
    /* background-color: rgb(93, 176, 199); */
    background-color: #100f13;
    color: rgb(93, 176, 199);
    border: none;
    /* color: black; */
    border-radius: 8px;
    transition: all 0.25s ease-in-out;
    font-weight: 700;
    /* transition: 0.25s ease-in-out; */
  }

  .course-edit__ul-btn:hover {
    background: rgb(93, 176, 199);
    color: black;
  }

  #new-module, #new-lesson {
    background: transparent;
    border: 2px solid rgb(93, 176, 199);
  }

  #new-module button, #new-lesson button {
    aspect-ratio: 1 / 1;
    align-self: center;
    width: 50px;
    background-color: #26272c;
    border: none;
    color: rgb(93, 176, 199);
    border-radius: 5px;
  }
  
  .course-edit__modules-wrapper {
    margin: 25px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .course-edit__modules-ul {
    display: grid;
    box-sizing: border-box;
    padding: 0px;
    list-style: none;
    line-height: 2;
    overflow: hidden auto;
    gap: 40px;
    margin: 0 auto;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(150px, 180px));
    /* grid-auto-rows: 280px; */
    width: 100%;
  }
  
  .course-edit__modules-ul-li {
    padding: 10px 15px;
    box-sizing: border-box;
    text-align: center;
    background-color: transparent;
    border-radius: 12px;
    border: 2px solid rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    aspect-ratio: 1/1;

  }
  
  .course-edit__modules-ul-li-edit {
    background-color: transparent;
    border: none;
    color: white;
    position: absolute;
    left: 15px;
    top: 20px;
    font-size: 15px;
    padding: 0;
  }
  
  .course-edit__modules-ul-li-delete {
    position: absolute; 
    border: none;
    background-color: transparent;
    color: white;
    font-size: 18;
    top: 15px;
    right: 10px;
  }
  
  .course-edit__modules-ul-li-headline {
    margin: 0;
    font-size: 18px;
  }
  
  .course-edit__modules-ul-li-cover-wrapper {
    position: relative;
  }
  
  .course-edit__modules-ul-li-cover-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: none;
    opacity: 0;
  }
  
  .course-edit__modules-ul-li-img {
    width: 100%;
    max-width: 50%;
    /* max-width: 140px; */
    aspect-ratio: 1/1;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .course-edit__modules-ul-li-p {
    margin: 0
  }
  
  .course-edit__modules-ul-li-addButton {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border: 2px solid #5DB0C7;
    background-color: transparent;
    color: #5DB0C7;
    font-size: 22px;
    margin: auto 0;
  }
  
  .course-edit__students-wrapper {
    margin: 25px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .course-edit__students-wrapper-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .course-edit__students-wrapper-btn-wrapper-p {
    margin: 0 0 15px 0;
  }

  .course-edit__students-wrapper-btn-wrapper-span {
    color: #5DB0C7;
  }

  .course-edit__students-wrapper-btn-wrapper-btn {
    width: 180px;
    height: 40px;
    background-color: transparent;
    border: 2px solid #5DB0C7;
    color: #5DB0C7;
    border-radius: 7px;
  }

  .course-edit__students-wrapper-success {
    display: flex;
    align-items: center;
    background-color: #5DB0C7;
    color: white;
    height: 40px;
    width: 425px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 7px;
    position: fixed;
    top: 75%;
    right: 5%;
  }

  .course-edit__students-wrapper-success-div {
    margin: 0 15px 0 0;
    width: 25px;
    height: 25px;
    border: 2px solid black;
    color: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .course-edit__students-wrapper-success-div-tick {
    font-size: 12px;
    color: black;
  }

  /* .course-edit__students-wrapper-success-div svg {
    scale: 0.8;
  } */
  
  .course-edit__students-wrapper-success-p {
    margin: 0;
  }

  .course-edit__students-wrapper-group {
    width: 100%;
    margin: 0 0 25px 0;
  }
  
  .course-edit__students-wrapper-ul {
    display: grid;
    box-sizing: border-box;
    padding: 0px;
    list-style: none;
    line-height: 2;
    /* overflow: hidden auto; */
    gap: 15px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    /* grid-auto-rows: 50px; */
    width: 100%;
  }
  
  .course-edit__students-wrapper-ul-li {
    border: 2px solid rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 9px;
    cursor: pointer;
    position: relative;
  }
  
  .course-edit__students-wrapper-ul-li-selection-div {
    position: absolute;
    top: -10px;
    right: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background-color: #5DB0C7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .course-edit__students-wrapper-ul-li-btn {
    margin: 0;
    padding: 15px 0;
    font-size: 18px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
  }
  
  .course-edit__students-wrapper-btn {
    padding: 0;
    height: 45px;
    width: 180px;
    border: 2px solid #5DB0C7;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    margin: 25px 0 0 0;
    font-size: 16px;
  }

  .course-edit__form-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 40px;
    /* background-color: #d4d4d6; */
    /* background-color: rgb(93, 176, 199); */
    background-color: #26272c;
    color: rgb(93, 176, 199);
    border: none;
    /* color: black; */
    border-radius: 8px;
    transition: all 0.25s ease-in-out;
    font-weight: 700;
  }

  .course-edit__form-btn:hover {
    background-color: rgb(93, 176, 199);
    color: #26272c;
  }

  .course-edit__lesson-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .course-edit__lesson-content h3 {
    margin: 0;
  }

  .course-edit__button-submit {
    background-color: #26272c;
    max-width: 280px;
  }

  @media screen and (max-width: 1439px) {
    .course-edit__modules-ul-li {
      padding: 10px 5px;
    }

    .course-edit__modules-ul-li-img {
      max-width: 50%;
    }

    .course-edit__modules-ul-li-p {
      font-size: 16px;
    }
  }